var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Card",
    { staticClass: "w-full", attrs: { shadow: "" } },
    [
      _c("transition", { attrs: { name: "slideUp", mode: "out-in" } }, [
        !_vm.showSuccess
          ? _c("div", { key: "form", staticClass: "register-form" }, [
              _c("h4", { staticClass: "font-bold md:text-xl text-xl" }, [
                _vm._v(" Create a password for your account "),
              ]),
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.resetPassword.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("Input", {
                    staticClass: "mt-10",
                    attrs: {
                      type: "password",
                      id: "password",
                      placeholder: "New password",
                      width: "w-full",
                      errorText: "Wrong password format",
                      validation: _vm.rules.password,
                      revealPassword: "",
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                  _c("Input", {
                    staticClass: "mt-10",
                    attrs: {
                      id: "confirm password",
                      type: "password",
                      placeholder: "Confirm new password",
                      errorText: "Passwords do not match",
                      validation: _vm.password === _vm.confirm_password,
                      width: "w-full",
                      revealPassword: "",
                    },
                    model: {
                      value: _vm.confirm_password,
                      callback: function ($$v) {
                        _vm.confirm_password = $$v
                      },
                      expression: "confirm_password",
                    },
                  }),
                  _c("div", { staticClass: "text-grey mt-4" }, [
                    _c("p", { staticClass: "text-sm mb-1" }, [
                      _vm._v("Password should be"),
                    ]),
                    _c("div", { staticClass: "md:grid grid-cols-2" }, [
                      _c("div", { staticClass: "flex items-center" }, [
                        _c("div", [
                          _vm.rules.passwordLength
                            ? _c("img", {
                                staticClass: "place-self-center h-3 w-3 mr-3",
                                attrs: {
                                  src: require("@/assets/icons/tick.svg"),
                                  alt: "",
                                },
                              })
                            : _c("div", { staticClass: "dot mr-3 h-3 w-3" }),
                        ]),
                        _c("p", { staticClass: "text-sm" }, [
                          _vm._v("At least 8 characters long"),
                        ]),
                      ]),
                      _c("div", { staticClass: "flex items-center" }, [
                        _c("div", [
                          _vm.rules.passwordUppercase
                            ? _c("img", {
                                staticClass: "place-self-center h-3 w-3 mr-3",
                                attrs: {
                                  src: require("@/assets/icons/tick.svg"),
                                  alt: "",
                                },
                              })
                            : _c("div", { staticClass: "dot mr-3 h-3 w-3" }),
                        ]),
                        _c("p", { staticClass: "text-sm" }, [
                          _vm._v("Minimum one uppercase letter"),
                        ]),
                      ]),
                      _c("div", { staticClass: "flex items-center" }, [
                        _c("div", [
                          _vm.rules.passwordLowercase
                            ? _c("img", {
                                staticClass: "place-self-center h-3 w-3 mr-3",
                                attrs: {
                                  src: require("@/assets/icons/tick.svg"),
                                  alt: "",
                                },
                              })
                            : _c("div", { staticClass: "dot mr-3 h-3 w-3" }),
                        ]),
                        _c("p", { staticClass: "text-sm" }, [
                          _vm._v("At least one lowercase letter"),
                        ]),
                      ]),
                      _c("div", { staticClass: "flex items-center" }, [
                        _c("div", [
                          _vm.rules.passwordNumber
                            ? _c("img", {
                                staticClass: "place-self-center h-3 w-3 mr-3",
                                attrs: {
                                  src: require("@/assets/icons/tick.svg"),
                                  alt: "",
                                },
                              })
                            : _c("div", { staticClass: "dot mr-3 h-3 w-3" }),
                        ]),
                        _c("p", { staticClass: "text-sm" }, [
                          _vm._v("Minimum one number"),
                        ]),
                      ]),
                      _c("div", { staticClass: "flex items-center" }, [
                        _c("div", [
                          _vm.rules.passwordSpecialCharacter
                            ? _c("img", {
                                staticClass: "place-self-center h-3 w-3 mr-3",
                                attrs: {
                                  src: require("@/assets/icons/tick.svg"),
                                  alt: "",
                                },
                              })
                            : _c("div", { staticClass: "dot mr-3 h-3 w-3" }),
                        ]),
                        _c("p", { staticClass: "text-sm" }, [
                          _vm._v("At least one special character"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("Button", {
                    staticClass: "mt-10",
                    attrs: {
                      text: "Change password",
                      type: "submit",
                      loading: _vm.loading,
                      disabled: _vm.disableButton,
                      width: "w-full",
                      shadow: "",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "mt-2 md:mt-6" }, [
                _c(
                  "p",
                  { staticClass: "text-sm text-center text-grey" },
                  [
                    _vm._v(" Already have an Account? "),
                    _c("router-link", { attrs: { to: "/login" } }, [
                      _c("span", { staticClass: "text-brandPurple" }, [
                        _vm._v("Sign In"),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _vm.showSuccess
          ? _c(
              "div",
              { key: "message", staticClass: "message h-full p-8" },
              [
                _c("img", {
                  staticClass: "h-32 w-32 mx-auto",
                  attrs: {
                    src: "https://res.cloudinary.com/zillaafrica/image/upload/v1624100575/customer/Group_707_1_tpsp0o.svg",
                    alt: "success",
                  },
                }),
                _c(
                  "h2",
                  {
                    staticClass:
                      "text-center mt-8 text-lg lg:text-xl font-bold",
                  },
                  [_vm._v(" Password reset successful ")]
                ),
                _c("p", { staticClass: "mt-2.5 text-grey text-center" }, [
                  _vm._v(" The zilla experience awaits you "),
                ]),
                _c("Button", {
                  staticClass: "mt-14",
                  attrs: { text: "Login", width: "w-full", shadow: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/login")
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }