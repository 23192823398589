<template>
  <Card class="w-full" shadow>
    <transition name="slideUp" mode="out-in">
      <div class="register-form" v-if="!showSuccess" key="form">
        <h4 class="font-bold md:text-xl text-xl">
          Create a password for your account
        </h4>
        <form @submit.prevent="resetPassword">
          <Input
            type="password"
            id="password"
            placeholder="New password"
            class="mt-10"
            v-model="password"
            width="w-full"
            errorText="Wrong password format"
            :validation="rules.password"
            revealPassword
          />
          <Input
            id="confirm password"
            type="password"
            placeholder="Confirm new password"
            class="mt-10"
            v-model="confirm_password"
            errorText="Passwords do not match"
            :validation="password === confirm_password"
            width="w-full"
            revealPassword
          />

          <div class="text-grey mt-4">
            <p class="text-sm mb-1">Password should be</p>
            <div class="md:grid grid-cols-2">
              <div class="flex items-center">
                <div>
                  <img
                    src="@/assets/icons/tick.svg"
                    alt=""
                    class="place-self-center h-3 w-3 mr-3"
                    v-if="rules.passwordLength"
                  />
                  <div class="dot mr-3 h-3 w-3" v-else></div>
                </div>
                <p class="text-sm">At least 8 characters long</p>
              </div>
              <div class="flex items-center">
                <div>
                  <img
                    src="@/assets/icons/tick.svg"
                    alt=""
                    class="place-self-center h-3 w-3 mr-3"
                    v-if="rules.passwordUppercase"
                  />
                  <div class="dot mr-3 h-3 w-3" v-else></div>
                </div>
                <p class="text-sm">Minimum one uppercase letter</p>
              </div>
              <div class="flex items-center">
                <div>
                  <img
                    src="@/assets/icons/tick.svg"
                    alt=""
                    class="place-self-center h-3 w-3 mr-3"
                    v-if="rules.passwordLowercase"
                  />
                  <div class="dot mr-3 h-3 w-3" v-else></div>
                </div>
                <p class="text-sm">At least one lowercase letter</p>
              </div>
              <div class="flex items-center">
                <div>
                  <img
                    src="@/assets/icons/tick.svg"
                    alt=""
                    class="place-self-center h-3 w-3 mr-3"
                    v-if="rules.passwordNumber"
                  />
                  <div class="dot mr-3 h-3 w-3" v-else></div>
                </div>
                <p class="text-sm">Minimum one number</p>
              </div>
              <div class="flex items-center">
                <div>
                  <img
                    src="@/assets/icons/tick.svg"
                    alt=""
                    class="place-self-center h-3 w-3 mr-3"
                    v-if="rules.passwordSpecialCharacter"
                  />
                  <div class="dot mr-3 h-3 w-3" v-else></div>
                </div>
                <p class="text-sm">At least one special character</p>
              </div>
            </div>
          </div>

          <Button
            text="Change password"
            class="mt-10"
            type="submit"
            :loading="loading"
            :disabled="disableButton"
            width="w-full"
            shadow
          />
        </form>
        <div class="mt-2 md:mt-6">
          <p class="text-sm text-center text-grey">
            Already have an Account?
            <router-link to="/login"
              ><span class="text-brandPurple">Sign In</span></router-link
            >
          </p>
        </div>
      </div>
      <div v-if="showSuccess" class="message h-full p-8" key="message">
        <img
          class="h-32 w-32 mx-auto"
          src="https://res.cloudinary.com/zillaafrica/image/upload/v1624100575/customer/Group_707_1_tpsp0o.svg"
          alt="success"
        />
        <h2 class="text-center mt-8 text-lg lg:text-xl font-bold">
          Password reset successful
        </h2>

        <p class="mt-2.5 text-grey text-center">
          The zilla experience awaits you
        </p>

        <Button
          text="Login"
          class="mt-14"
          width="w-full"
          @click="$router.push('/login')"
          shadow
        />
      </div>
    </transition>
  </Card>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import { Input } from "@/UI/Input";
  import { Button } from "@/UI/Button";
  export default {
    components: {
      Input,
      Button,
    },
    data() {
      return {
        password: "",
        confirm_password: "",
        showSuccess: false,
        loading: false,
      };
    },
    computed: {
      ...mapState({
        id: (state) => state.auth.password_id,
      }),
      rules() {
        return {
          password:
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(
              this.password
            ),
          confirm_password: this.password === this.confirm_password,
          passwordLength: this.password.length >= 8,
          passwordUppercase: /^(?=.*[A-Z])/.test(this.password),
          passwordLowercase: /^(?=.*?[a-z])/.test(this.password),
          passwordNumber: /^(?=.*?[0-9])/.test(this.password),
          passwordSpecialCharacter:
            /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(this.password),
        };
      },
      disableButton() {
        return Object.values(this.rules).includes(false);
      },
    },
    methods: {
      ...mapActions("auth", ["completePasswordReset"]),
      ...mapActions("notification", ["showAlert"]),
      resetPassword() {
        this.loading = true;
        this.completePasswordReset({
          passwordResetId: this.id,
          newPassword: this.password,
        })
          .then(() => {
            this.showSuccess = true;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
    },
  };
</script>
<style scoped>
  @import "../../../assets/styles/animation.css";
  /* .register-container {
    min-height: 100vh;
  } */
  .register-container h3 {
    font-size: 35px;
  }
  .register-container p {
    line-height: 30.15px;
  }
  .container-register {
    max-width: 33.831rem;
  }
  .resend-otp {
    position: absolute;
    top: 0;
    right: 0;
  }
  .dot {
    border-radius: 50%;
    border: 1px solid #bdbeda;
  }

  @media (max-width: 768px) {
    .register-container {
      min-height: calc(100vh - 15rem);
      max-width: 100%;
    }
  }
</style>
